import type { FC } from 'react';
import React, { memo, useMemo, useState } from 'react';

import type {
	PageCardAppearance as PageCardAppearanceType,
	PageCard as PageCardType,
} from './card-types';
import { PageCardContext } from './PageCardContext';
import { QuickActionsEnum } from './Items/QuickActions/QuickActionsEnum';
import type { AnalyticsData } from './PageCards';

export type PageCardContextProviderProps = {
	appearance: PageCardAppearanceType;
	data: PageCardType;
	ranking?: number;
	analyticsData?: AnalyticsData;
	children?: React.ReactNode;
	isUnread?: boolean;
};

export const PageCardContextProvider: FC<PageCardContextProviderProps> = memo(
	({ appearance, data, ranking, analyticsData, isUnread, children }) => {
		const [isMenuOpen, setIsMenuOpen] = useState(false);
		const [isViewed, setIsViewed] = useState(false);
		const [selectedMenuItems, setSelectedMenuItems] = useState({
			[QuickActionsEnum.ShareButton]: false,
			[QuickActionsEnum.WatchButton]: false,
		});
		const pageCardContextValue = useMemo(() => {
			return {
				...data,
				appearance,
				setIsMenuOpen,
				isMenuOpen,
				ranking,
				setSelectedMenuItems,
				selectedMenuItems,
				analyticsData,
				isViewed,
				setIsViewed,
				isUnread,
			};
		}, [
			data,
			appearance,
			ranking,
			analyticsData,
			isMenuOpen,
			selectedMenuItems,
			isViewed,
			isUnread,
		]);

		return (
			<PageCardContext.Provider value={pageCardContextValue}>{children}</PageCardContext.Provider>
		);
	},
	(prevProps, nextProps) =>
		prevProps.appearance === nextProps.appearance &&
		prevProps.data.isStarred === nextProps.data.isStarred,
);
